import { Note as NoteIcon } from '@tcl-boron-icons/icons';
import { Button } from '@tcl-boron-prefabs/button';
import Loader from '@tcl-boron-prefabs/loader';
import { TextArea } from '@tcl-boron-prefabs/text-area';
import { Toggle } from '@tcl-boron-prefabs/toggle';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { Activity, ActivityType, Note, ROLES } from '@tempus/t-shared';
import { storeActions } from '@tempus/t-shared/ui';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Warning } from 'tcl-v3/icons';

import { RootState } from '~/store';
import api from '~/store/api';
import { formatDateForDisplay } from '~/utils/misc';

import { ActivityStatus, CombinedStatus, GroupedActivities, groupActivities } from '../ActivityStatus';
import useStyles from './ActivityTab.styles';

interface ActivityTab {
  stateId: string;
  patientLinkageId: string;
  responseRequired: boolean;
  institutionId: string;
  activities: Activity[];
  setShouldFetchData: (newState: boolean) => void;
  setIsThereUnsavedNotes: (value: boolean) => void;
  onActivityUpdate: () => void;
}

export const ActivityTab = ({
  stateId,
  patientLinkageId,
  responseRequired,
  institutionId,
  activities,
  setShouldFetchData,
  setIsThereUnsavedNotes,
  onActivityUpdate,
}: ActivityTab) => {
  const dispatch = useDispatch();
  const notesContainerRef = useRef<HTMLDivElement | null>(null);
  const [note, setNote] = useState('');
  const { effectiveRoles } = useSelector((state: RootState) => state.user);
  const [isToggleSelected, setIsToggleSelected] = useState<boolean>(responseRequired);
  const classes = useStyles();
  const isInternalUser = effectiveRoles.includes(ROLES.T_SITE_INTERNAL_USER);
  const isSiteUser = effectiveRoles.includes(ROLES.T_SITE_BASE_USER) && !isInternalUser;
  const { loading } = useSelector(({ patientTrackerV2 }: RootState) => patientTrackerV2[stateId].patient);

  useEffect(() => {
    scrollToBottom();
  }, [activities]);

  const handleToggle = async () => {
    try {
      await api.patients.updateTimePatient(patientLinkageId, institutionId, { responseRequired: !isToggleSelected });
      onActivityUpdate();
      setIsToggleSelected(!isToggleSelected);
      dispatch(storeActions.notification.showSuccessMessage('Record updated'));
      setShouldFetchData(true);
    } catch (err) {
      console.error(err);
      dispatch(storeActions.notification.showErrorMessage('Failed to update record.'));
    }
  };

  const handleSource = (note: Activity) => {
    if (note.type === ActivityType.Note) {
      const sourceTypes = {
        TEMPUS: 'Tempus note',
        SITE: 'Site note',
      };

      return sourceTypes[(note.content as Note).source];
    }
  };

  const scrollToBottom = () => {
    const container = notesContainerRef.current;
    if (container) {
      const scroll = container.scrollHeight - container.clientHeight;
      container.scrollTo({ top: scroll, behavior: 'smooth' });
    }
  };

  const addNote = () => {
    api.patients
      .createNote(patientLinkageId, { institutionId, note })
      .then(() => {
        onActivityUpdate();
        setNote('');
        setIsThereUnsavedNotes(false);
        scrollToBottom();
      })
      .catch((err) => {
        console.error(err);
        dispatch(storeActions.notification.showErrorMessage('Failed to create note.'));
      });
  };

  const handleNoteChange = (note: string) => {
    setNote(note);
    if (!note) {
      setIsThereUnsavedNotes(false);
    } else {
      setIsThereUnsavedNotes(true);
    }
  };

  return (
    <div className={classes.notesTab}>
      <div className={classes.notes} ref={notesContainerRef}>
        {loading ? (
          <div className={classes.activitiesLoader}>
            <Loader size="large" />
            <div>Loading activities</div>
          </div>
        ) : (
          <>
            {groupActivities(activities).map((activity, idx) => {
              switch (activity.type) {
                case ActivityType.Note:
                  return (
                    <div className={classes.note} key={activity.id} data-testid="update-overlay-activity-note">
                      <>
                        <div className={classes.noteHeader}>
                          <div className={classes.headerLeft}>
                            <NoteIcon className={classes.noteIcon} />
                            <strong className={cn(typography.body, classes.noteSource)}>
                              {handleSource(activity)}
                            </strong>
                          </div>
                          <div>
                            <p className={cn(typography.body, classes.noteCreatedDate)}>
                              {formatDateForDisplay(activity.createdAt)}
                            </p>
                          </div>
                        </div>
                        <div className={classes.noteBody}>
                          <p className={cn(typography.body, classes.noteText)}>{(activity.content as Note).note}</p>
                          <span className={cn(typography.supportingBody, typography.gray)}>- {activity.userName}</span>
                        </div>
                      </>
                    </div>
                  );
                case CombinedStatus:
                  return <ActivityStatus key={activity.type + idx} groupedActivities={activity as GroupedActivities} />;
                default:
                  return null;
              }
            })}
          </>
        )}
      </div>
      {isInternalUser && (
        <div className={classes.toggleContainer}>
          <Toggle
            label="Site response required"
            isSelected={isToggleSelected}
            labelPlacement="left"
            onChange={handleToggle}
            hideCheckMark
            data-testid="update-overlay-activity-toggle"
          />
        </div>
      )}
      <div
        className={cn(
          classes.addNoteContainer,
          isSiteUser && isToggleSelected ? classes.textAreaResponseRequired : '',
        )}>
        <TextArea
          disabled={false}
          textAreaType="primary"
          onChange={(note) => handleNoteChange(note)}
          label="Add note"
          value={note}
          placeholder="Tell us about patient progress and any changes to the treatment plan"
          data-testid="update-overlay-activity-text-area"
        />
        {isSiteUser && isToggleSelected && (
          <div className={classes.warning}>
            <Warning />{' '}
            <p className={cn(typography.body)} data-testid="update-overlay-activity-response-required-warning">
              Response needed
            </p>
          </div>
        )}

        <div className={classes.addNoteBtnContainer}>
          <Button
            buttonType="secondary"
            small
            ariaLabel=""
            onClick={addNote}
            disabled={isEmpty(note)}
            data-testid="update-overlay-activity-add-note-btn">
            Add Note
          </Button>
        </div>
      </div>
    </div>
  );
};
