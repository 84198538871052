import { ErrorPalette } from '@tcl-boron-colors/colors';
import { Card } from '@tcl-boron-prefabs/card';
import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import { TextArea } from '@tcl-boron-prefabs/text-area';
import { TextInput } from '@tcl-boron-prefabs/text-input';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { TimeProgramSiteStatus } from '@tempus/t-shared';
import { NO_LONGER_A_CANDIDATE_REASONS } from '@tempus/t-shared/src/constants/patient-tracker';
import cn from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LinkOut, Warning } from 'tcl-v3/icons';
import { DropdownOption } from 'tcl-v3/models';

import { RootState } from '~/store';
import { TrialMatchesUpdates } from '~/store/api/types';
import { getPatientTrackerStatusDropdownOptions, PATIENT_TRACKER_STATUSES } from '~/store/patientTrackerV2/constants';
import { openTrialMaterials, trialStatusToDisplay } from '~/store/patientTrackerV2/helpers';
import { PatientTrackingDetails } from '~/store/patientTrackerV2/types';

import useStyles from './TrialMatchCard.styles';
import { getChangedValues } from './utils';

interface TrialMatchCardProps {
  ptd: PatientTrackingDetails;
  handleTrialMatchesChanges: (ptdId: string, changes: Omit<TrialMatchesUpdates, 'id'>) => void;
}

export const TrialMatchCard: React.FC<TrialMatchCardProps> = ({ ptd, handleTrialMatchesChanges }) => {
  const classes = useStyles();
  const selectedUserSite = useSelector((state: RootState) => state.site.selectedUserSite);
  const isInternalUser = useSelector(
    (state: RootState) => state.user.canViewAllPatientTracking && state.user.canWritePatientTracking,
  );
  const [isMatchDetailsOpen, setIsMatchDetailsOpen] = useState(false);
  const [screeningNote, setScreeningNote] = useState<string>(ptd.tempusToPortalLatestPortalPatientUpdate || '');
  const [mutations, setMutations] = useState<string>(ptd.mutations || '');

  const [selectedMatchStatus, setSelectedMatchStatus] = useState<DropdownOption | null>({
    label: ptd.status,
    value: ptd.status,
  });

  const [reasonNotAMatch, setReasonNotAMatch] = useState<DropdownOption | null>(
    ptd.reasonNotAMatch
      ? {
          label: ptd.reasonNotAMatch,
          value: ptd.reasonNotAMatch,
        }
      : null,
  );
  const [reasonNotAMatchDetails, setReasonNotAMatchDetails] = useState<string>(ptd.reasonNotAMatchDetails || '');

  function getInitialValues() {
    const keys = [
      'status',
      'reasonNotAMatch',
      'reasonNotAMatchDetails',
      'tempusToPortalLatestPortalPatientUpdate',
      'mutations',
    ];
    const initialValues = {};
    keys.forEach((key) => {
      initialValues[key] = ptd[key] || null;
    });

    return initialValues;
  }

  function updateSelectedMatchStatus(matchStatus: DropdownOption) {
    setSelectedMatchStatus(matchStatus);

    if (matchStatus.value === PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE) {
      setReasonNotAMatch(null);
      setReasonNotAMatchDetails('');
    }
  }

  useEffect(() => {
    const currentValues = {
      status: selectedMatchStatus?.value || null,
      reasonNotAMatch: reasonNotAMatch?.value || null,
      tempusToPortalLatestPortalPatientUpdate: screeningNote || null,
      mutations: mutations || null,
      reasonNotAMatchDetails: reasonNotAMatchDetails || null,
    };
    const changedValues: Omit<TrialMatchesUpdates, 'id'> = getChangedValues(getInitialValues(), currentValues);
    if (changedValues.status && changedValues.status !== PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE) {
      changedValues.reasonNotAMatch = null;
      changedValues.reasonNotAMatchDetails = null;
    }
    handleTrialMatchesChanges(ptd.id, changedValues);
  }, [screeningNote, mutations, selectedMatchStatus, reasonNotAMatch, reasonNotAMatchDetails]);

  return (
    <Card className={classes.trialMatchCard}>
      <>
        <div className={classes.trialDetailsSection}>
          <div className={classes.header}>{ptd.trial?.shortName}</div>
          <div className={classes.twoColumns}>
            <div>{ptd.arms?.map((arm) => arm.name).join(', ')}</div>
            <i>{trialStatusToDisplay(ptd.programSite?.status as TimeProgramSiteStatus)}</i>
          </div>
          <Card className={classes.matchDetailsCard}>
            <>
              {isMatchDetailsOpen ? (
                <>
                  <div className={classes.header}>About the match</div>
                  {isInternalUser ? (
                    <TextArea
                      textAreaType="secondary"
                      label="Screening note"
                      value={screeningNote}
                      onChange={setScreeningNote}
                      className={classes.biomarkersTextArea}
                    />
                  ) : (
                    <div>
                      <p>
                        Updated{' '}
                        {ptd.tempusToPortalLatestPortalUpdateModifiedDate &&
                          moment(ptd.tempusToPortalLatestPortalUpdateModifiedDate)
                            .utc()
                            .format('MMM DD, YYYY')}
                      </p>
                      <p>{screeningNote}</p>
                    </div>
                  )}
                  {isInternalUser ? (
                    <TextArea
                      textAreaType="secondary"
                      label="Matching biomarkers"
                      value={mutations}
                      onChange={setMutations}
                      className={classes.biomarkersTextArea}
                    />
                  ) : (
                    <div className={classes.biomarkers}>
                      <div>Matching biomarkers</div>
                      <div>{mutations}</div>
                    </div>
                  )}
                  <div
                    className={classes.trialMaterials}
                    onClick={() => openTrialMaterials(ptd.trial.id, selectedUserSite)}
                    data-pendo-id="update_pt-view_trial_materials">
                    <LinkOut />
                    View trial materials
                  </div>
                </>
              ) : (
                <div className={classes.oneLineStatus}>{ptd.tempusToPortalLatestPortalPatientUpdate}</div>
              )}
              <div
                className={cn(typography.link)}
                onClick={() => setIsMatchDetailsOpen(!isMatchDetailsOpen)}
                data-pendo-id={`update_pt-${isMatchDetailsOpen ? 'hide' : 'view'}_match_details`}>
                {isMatchDetailsOpen ? '- Hide' : '+ View'} match details
              </div>
            </>
          </Card>
        </div>
        <div className={classes.divider}></div>
        <div className={classes.matchStatusSection}>
          <div data-testid="match-status-title" className={classes.matchStatusTitle}>
            <b>Match status</b>
          </div>
          <div className={classes.matchStatusDropdownContainer}>
            <SingleSelectDropdown
              className={classes.statusDropdown}
              onChange={(status) => updateSelectedMatchStatus(status!)}
              value={selectedMatchStatus}
              label=""
              hideLabel
              data-testid="update-overlay-match-status"
              options={getPatientTrackerStatusDropdownOptions()}
              escapeClippingArea
              data-pendo-id="update_pt-match_status"
            />
          </div>
          {selectedMatchStatus?.value === PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE && (
            <>
              <SingleSelectDropdown
                className={classes.reasonDropdown}
                onChange={(reason) => setReasonNotAMatch(reason)}
                value={reasonNotAMatch}
                label="Reason"
                data-testid="update-overlay-reason-not-a-match"
                data-pendo-id="update_pt-reason_not_a_match"
                options={Object.values(NO_LONGER_A_CANDIDATE_REASONS).map((value) => ({ label: value, value }))}
                escapeClippingArea
                status={!reasonNotAMatch ? 'error' : 'default'}
              />
              {!reasonNotAMatch && (
                <div className={classes.warning}>
                  <div className={classes.warningIcon}>
                    <Warning width={12} height={12} color={ErrorPalette[700]} />
                  </div>
                  Reason is required
                </div>
              )}
              <TextInput
                label="Additional details"
                className={classes.additionalDetails}
                onChange={setReasonNotAMatchDetails}
                value={reasonNotAMatchDetails}
                inputType="primary"
                clearable
              />
            </>
          )}
        </div>
      </>
    </Card>
  );
};

export default TrialMatchCard;
