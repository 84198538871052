import { NavTab, NavTabGroup } from '@tcl-boron-prefabs/nav-tab';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { ROLES } from '@tempus/t-shared';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { TimePatient } from '~/store/patientTrackerV2/types';

import useStyles from './RightPane.styles';
import { ActivityTab } from './TabContents/ActivityTab';
import PatientDetailsTab from './TabContents/PatientDetailsTab';

interface RightPaneProps {
  stateId: string;
  timePatient: TimePatient;
  setShouldFetchData: React.Dispatch<React.SetStateAction<boolean>>;
  setIsThereUnsavedNotes: React.Dispatch<React.SetStateAction<boolean>>;
  refreshData: () => void;
}
export default ({ stateId, timePatient, setShouldFetchData, setIsThereUnsavedNotes, refreshData }: RightPaneProps) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('activity');
  const { effectiveRoles } = useSelector((state: RootState) => state.user);

  const ACTIVITY_ID = 'activity';
  const PATIENT_DETAIL_ID = 'patientDetail';
  const tabs = [
    {
      id: ACTIVITY_ID,
      display: 'Activity',
    },
    {
      id: PATIENT_DETAIL_ID,
      display: 'Patient details',
      allowedRoles: [ROLES.T_PATIENT_TRACKER_READ_INTERNAL],
    },
  ];

  const renderTabs = () =>
    tabs.map(({ id, display, allowedRoles }) => {
      if (allowedRoles && !effectiveRoles.some((role) => allowedRoles.includes(role))) {
        return;
      }

      return (
        <NavTab
          value={id}
          key={id}
          onClick={() => setCurrentTab(id)}
          className={classes.navTab}
          activeClassName={classes.activeTab}
          data-testid={`update-overlay-${id}-tab`}>
          <strong className={typography.body}>{display}</strong>
        </NavTab>
      );
    });

  const renderContent = () => {
    switch (currentTab) {
      case ACTIVITY_ID:
        return (
          <ActivityTab
            stateId={stateId}
            patientLinkageId={timePatient.patientLinkageId}
            responseRequired={timePatient.responseRequired}
            institutionId={timePatient.institution.id}
            activities={timePatient.activities}
            setShouldFetchData={setShouldFetchData}
            setIsThereUnsavedNotes={setIsThereUnsavedNotes}
            onActivityUpdate={refreshData}
          />
        );
      case PATIENT_DETAIL_ID:
        return <PatientDetailsTab timePatient={timePatient} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>
        <NavTabGroup value={currentTab} className={classes.navTabGroup}>
          {renderTabs()}
        </NavTabGroup>
        {renderContent()}
      </div>
    </div>
  );
};
