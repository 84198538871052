import { Badge, BadgeProps } from '@tcl-boron-prefabs/badge';
import { PROGRAM_TYPES_DEC, getStatusBadgeStyling, getTimeProgramSiteStatusBadgeText } from '@tempus/t-shared';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { SearchArm } from '~/store/api/types';
import { getSummaryDisplays } from '~/store/trial/utils';
import { getBadgeWidth } from '~/utils/misc';

export const Cohort = (props: SearchArm): JSX.Element => {
  const classes = createUseStyles({
    wrapper: {
      '& td': {
        padding: '0 15px 10px',
        verticalAlign: 'top',
      },
    },
    first: {
      display: 'flex',
      flexDirection: 'row',
    },
  })();

  const selectedUserSite = useSelector(({ site }: RootState) => site.selectedUserSite?.name);
  const { name, armSummary, programType, displayStatus } = props;
  const summary = armSummary || {};
  const { agentString, indicationsString, phaseString, biomarkersString } = getSummaryDisplays(summary);

  return (
    <tr className={classes.wrapper}>
      <td className={classes.first}>
        {(selectedUserSite !== 'All Sites' || programType !== PROGRAM_TYPES_DEC.ST) && (
          <span style={{ minWidth: '120px' }}>
            <Badge
              style={{ maxHeight: '50px', width: `${getBadgeWidth(displayStatus)}` }}
              text={getTimeProgramSiteStatusBadgeText(displayStatus)}
              badgeVariant={getStatusBadgeStyling(displayStatus).variant as BadgeProps['badgeVariant']}
              badgeType={getStatusBadgeStyling(displayStatus).type}
            />
          </span>
        )}
        <span style={{ paddingLeft: '10px' }}>{name}</span>
      </td>

      <td>{agentString}</td>
      <td>{indicationsString}</td>
      <td>{phaseString}</td>
      <td>{biomarkersString}</td>
      <td>{/* Placeholder for parent table documents icon */}</td>
    </tr>
  );
};
