import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { Activity, ActivityType, MatchStatusUpdates, NewTrialMatch, PatientStatusUpdate } from '@tempus/t-shared';
import cn from 'classnames';
import { findLast } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AddSimple } from 'tcl-v3/icons';

import { RootState } from '~/store';
import { formatDateForDisplay } from '~/utils/misc';
import { getTrialDisplay } from '~/utils/trial';

import useStyles from './ActivityStatus.styles';

export const ActivityStatus = ({ groupedActivities }: { groupedActivities: GroupedActivities }) => {
  const trials = useSelector(({ trial }: RootState) => trial.allTrials);

  const [isOpen, setIsOpen] = useState(groupedActivities.isLast);
  const classes = useStyles();
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  let currentDate = '';

  return (
    <>
      {!isOpen ? (
        <div
          className={cn(classes.viewLink, classes.viewMore)}
          onClick={toggleOpen}
          data-testid="update-overlay-activity-view-more">
          <AddSimple height={8} width={8} /> View ({groupedActivities.data.length}) patient updates
        </div>
      ) : (
        <>
          <div className={classes.activityStatusContainer}>
            <ul className={classes.ul}>
              {groupedActivities.data.map((activity) => {
                const showDate = currentDate !== activity.createdAt;
                currentDate = activity.createdAt;
                switch (activity.type) {
                  case ActivityType.MatchStatusUpdates:
                    return (
                      <li key={activity.id} className={cn(typography.body, classes.li)}>
                        <div className={classes.listItemContainer}>
                          <div className={classes.updatesLeft}>
                            Patient Updated to <em>{(activity.content as MatchStatusUpdates).newStatus}</em> for{' '}
                            <strong>{getTrialDisplay((activity.content as MatchStatusUpdates).trialId, trials)}</strong>
                          </div>
                          <div>{showDate && activity.createdAt}</div>
                        </div>
                      </li>
                    );
                  case ActivityType.NewTrialMatch:
                    return (
                      <li key={activity.id} className={cn(typography.body, classes.li)}>
                        <div className={classes.listItemContainer}>
                          <div className={classes.updatesLeft}>
                            Patient has a new match to{' '}
                            <strong>{getTrialDisplay((activity.content as NewTrialMatch).trialId, trials)}</strong>
                          </div>
                          <div>{showDate && activity.createdAt}</div>
                        </div>
                      </li>
                    );
                  case ActivityType.PatientStatusUpdate:
                    return (
                      <li key={activity.id} className={cn(typography.body, classes.li)}>
                        <div className={classes.listItemContainer}>
                          <div className={classes.updatesLeft}>
                            Patient status has been changed to{' '}
                            <em>{(activity.content as PatientStatusUpdate).newStatus}</em>
                          </div>
                          <div>{showDate && activity.createdAt}</div>
                        </div>
                      </li>
                    );
                  default:
                    return null;
                }
              })}
            </ul>
            <div className={cn(classes.viewLink)} onClick={toggleOpen} data-testid="update-overlay-activity-view-less">
              - View Less
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const CombinedStatus = 'CombinedStatus';

export interface GroupedActivities {
  type: typeof CombinedStatus;
  data: Activity[];
  isLast: boolean;
}

export const groupActivities = (activities: Activity[]) => {
  const result: (Activity | GroupedActivities)[] = [];

  activities.forEach((activity) => {
    if (activity.type === ActivityType.Note) {
      result.push(activity);
      return;
    }

    const lastElement = result[result.length - 1];
    activity.createdAt = formatDateForDisplay(activity.createdAt);
    if (lastElement && lastElement.type === CombinedStatus) {
      (lastElement as GroupedActivities).data.push(activity);
    } else {
      result.push({
        type: CombinedStatus,
        data: [activity],
        isLast: false,
      });
    }
  });

  const lastGroupedActivities = findLast(result, { type: CombinedStatus });

  if (lastGroupedActivities) {
    (lastGroupedActivities as GroupedActivities).isLast = true;
  }

  return result;
};
