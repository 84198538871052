import { Trial } from '~/store/trial/types';

export const ctgovUrl = (nctId: string): string => `https://clinicaltrials.gov/ct2/show/${nctId}`;

export const getTrialDisplay = (trialId: string, trials: Trial[]) => {
  const trialsLookup = trials.reduce((acc, t: Trial) => {
    acc[t.id] = t;
    return acc;
  }, {} as Record<string, Trial>);
  return trialsLookup[trialId]?.shortName || trialsLookup[trialId]?.nctId || trialId;
};
