import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    height: '100%',
    padding: '24px',
    borderLeft: `1px solid ${GrayscalePalette[300]}`,
  },
  containerInner: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  navTabGroup: {
    '&::after': {
      borderBottom: `1px solid ${GrayscalePalette[300]}`,
    },
  },
  navTab: {
    padding: '0px 16px',
    borderColor: GrayscalePalette[300],
    marginBottom: '-2px', // account for spacing from border
    '& strong': {
      fontWeight: '600',
    },
  },
  activeTab: {
    backgroundColor: GrayscalePalette[200],
    borderTop: 'none',
    borderLeft: 'none',
  },
});
