import { ErrorPalette, GrayscalePalette, WarningPalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  updateOverlayWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '50%',
  },
  updateOverlayHeader: {
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '36.4px',
    color: 'black',
    marginBottom: '16px',
    wordWrap: 'break-word',
  },
  patientDetailsHeader: {
    fontSize: '14px',
    padding: '24px',
    borderBottom: `1px solid ${GrayscalePalette[300]}`,
  },
  updateOverlaySubHeader: {
    fontWeight: 700,
    lineHeight: '20px',
    color: 'black',
  },
  updateOverlayTwoColumnContainer: {
    display: 'flex',
    gap: '16px',
    alignItems: 'flex-end',

    '& > div': {
      flex: 1,
    },
  },
  visitDetailsContainer: {
    alignItems: 'flex-start',
  },
  dateWarning: {
    marginTop: '4px',
    fontSize: '12px',
    color: `${WarningPalette[700]}`,
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    height: '12px',
    marginRight: '4px',
  },
  dateError: {
    marginTop: '4px',
    fontSize: '12px',
    color: `${ErrorPalette[700]}`,
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    height: '12px',
    marginRight: '4px',
  },
  date: {
    boxSizing: 'border-box',
  },
  bottomButtons: {
    display: 'flex',
    padding: '24px',
    justifyContent: 'flex-end',
    gap: '12px',
    marginTop: 'auto',
    borderTop: `1px solid ${GrayscalePalette[300]}`,
  },
  closeButton: {
    top: '20px',
    right: '20px',
    border: 'none',
    cursor: 'pointer',
    margin: '-5px',
    padding: '12px 14px',
    position: 'absolute',
    zIndex: 1,
  },
  mainBody: {
    height: 'inherit',
    padding: '24px',
    background: `${GrayscalePalette[200]}`,
    overflowY: 'auto',
  },
  patientStatusContainer: {
    paddingBottom: '16px',
  },
});
