import { Card } from '@tcl-boron-prefabs/card';
import React from 'react';
import { Information } from 'tcl-v3/icons';

import trialMatchCardStyles from './TrialMatchCard.styles';

export const NoActiveTrialMatchesCard: React.FC = () => {
  const classes = trialMatchCardStyles();

  return (
    <Card className={classes.trialMatchCard}>
      <>
        <div className={classes.trialDetailsSection}>
          <div className={classes.inactiveMatchesCardHeader}>
            <div className={classes.inactiveCardTitle}>
              <Information />
              <span className={classes.inactiveCardTitleText}>No active trial matches </span>
            </div>
            <div className={classes.inactiveCardLink}>
              <a href="">View inactive matches</a>
            </div>
          </div>
          <div className={classes.inactiveCardMessage}>
            This patient has no trials they are being considered for. Check the inactive matches to see the patient’s
            screening history.
          </div>
        </div>
      </>
    </Card>
  );
};

export default NoActiveTrialMatchesCard;
