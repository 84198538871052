import { ALL_SITES } from '@tempus/t-shared';
import { MaxPageWidth } from '@tempus/t-shared/ui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';

import PatientTracker from '~/components/PatientTracker';
import PatientTrackerHeader from '~/components/PatientTrackerHeader';
import { Routes } from '~/routes';
import { RootState } from '~/store';
import { creators as patientTrackerCreators } from '~/store/patientTrackerV2';
import { PatientTrackerFilterField, PatientTrackerStateId } from '~/store/patientTrackerV2/types';
import { creators as trialCreators } from '~/store/trial';

interface RouteMatchParams {
  siteId: string;
  patientFullName: string;
  nctId: string;
}

const SitePatientTracker: React.FC<RouteComponentProps<RouteMatchParams>> = ({ match }) => {
  const dispatch = useDispatch();
  const selectedUserSite = useSelector(({ site }: RootState) => site.selectedUserSite);
  const timePatients =
    useSelector(
      ({ patientTrackerV2 }: RootState) => patientTrackerV2[PatientTrackerStateId.PATIENT_TRACKER_V2].patients,
    ) || [];
  const patientsMetadata =
    useSelector(
      ({ patientTrackerV2 }: RootState) => patientTrackerV2[PatientTrackerStateId.PATIENT_TRACKER_V2].patientsMetadata,
    ) || {};
  const history = useHistory();
  const location = useLocation();

  const [disabledFilters, setDisabledFilters] = useState([
    PatientTrackerFilterField.INDICATIONS,
    PatientTrackerFilterField.RN_ASSIGNMENT,
  ]);

  const {
    params: { siteId, patientFullName },
  } = match;

  useEffect(() => {
    dispatch(patientTrackerCreators.getPatientTrackerCategoryCounts(PatientTrackerStateId.PATIENT_TRACKER_V2, siteId));
  }, [siteId]);

  useEffect(() => {
    dispatch(trialCreators.getAllTrials());
    history.push(
      Routes.sitePatientTracker.path
        .replace(':siteId?/', selectedUserSite?.id || ALL_SITES)
        .replace(':patientFullName?', ``)
        .replace(':nctId?', ``) + location.search,
    );

    if (selectedUserSite?.id) {
      setDisabledFilters([PatientTrackerFilterField.INDICATIONS, PatientTrackerFilterField.RN_ASSIGNMENT]);

      dispatch(
        patientTrackerCreators.setPatientTrackerFilters(PatientTrackerStateId.PATIENT_TRACKER_V2, { rnAssignment: '' }),
      );
    } else if (selectedUserSite?.name === ALL_SITES) {
      setDisabledFilters([PatientTrackerFilterField.INDICATIONS]);
    }
  }, [selectedUserSite]);

  return (
    <>
      <MaxPageWidth>
        <PatientTracker
          stateId={PatientTrackerStateId.PATIENT_TRACKER_V2}
          header={
            <PatientTrackerHeader
              title="Patient tracker"
              stateId={PatientTrackerStateId.PATIENT_TRACKER_V2}
              siteId={siteId}
            />
          }
          visibleFilters={Object.keys(PatientTrackerFilterField)
            .map((field) => PatientTrackerFilterField[field])
            .filter((field) => !disabledFilters.includes(field))}
          timePatients={timePatients}
          siteId={siteId}
          patientFullName={patientFullName}
          patientsMetadata={patientsMetadata}
        />
      </MaxPageWidth>
    </>
  );
};

export default withRouter(SitePatientTracker);
