import { PROGRAM_TYPES_DEC, ArmUtils } from '@tempus/t-shared';

import { SearchArm } from '~/store/api/types';
import { Cohort } from '~/store/trialSearch/state';

export const formatCohortOptions = (cohorts: (Cohort | SearchArm)[]) =>
  cohorts.map(({ id, name, programType }) => ({
    label: programType === PROGRAM_TYPES_DEC.ST ? ArmUtils.getCohortLabel(name, programType) : name,
    value: id,
    name,
    programType,
  }));
