import { isEqual } from 'lodash';

export function getChangedValues(obj1, obj2) {
  const changedValues = {};

  for (const key in obj1) {
    if (!isEqual(obj1[key], obj2[key])) {
      changedValues[key] = obj2[key];
    }
  }

  return changedValues;
}
