import { Button } from '@tcl-boron-prefabs/button';
import { MultiSelectDropdown } from '@tcl-boron-prefabs/multi-select-dropdown';
import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import { TextArea } from '@tcl-boron-prefabs/text-area';
import { TextInput } from '@tcl-boron-prefabs/text-input';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Fail } from 'tcl-v3/icons';
import { DateInputValue, DropdownOption, InputTheme } from 'tcl-v3/models';
import { SingleDatePicker } from 'tcl-v3/prefabs';
import typography from 'tcl-v3/styles/typography.module.scss';

import { RootState } from '~/store';
import { PATIENT_TRACKER_STATUSES, VisitType } from '~/store/patientTrackerV2/constants';
import { formatCohortOptions } from '~/utils/arm';
import { getPatientStatusOptions, shouldExcludePendingTempusReview } from '~/utils/patientTracker';

import useStyles from '../styles';
import {
  PatientInputFieldsEnum,
  PatientInputFields,
  PatientInputFieldsUpdates,
  AddPatientPage,
  CohortDropdownOption,
} from '../types';
import { getEnrollingCohorts } from '../utils';

const visitTypeOptions: DropdownOption[] = Object.entries(VisitType).map(([, visitType]) => ({
  label: visitType,
  value: visitType,
}));

interface PatientMatchInformationProps {
  patientInputFields: PatientInputFields;
  updatePatientInputFields: (updates: PatientInputFieldsUpdates) => void;
  isPersisting: boolean;
  navigateToPage: (page: AddPatientPage) => void;
  performAddPatient: () => void;
}

const PatientMatchInformation: React.FC<PatientMatchInformationProps> = ({
  patientInputFields,
  updatePatientInputFields,
  isPersisting,
  navigateToPage,
  performAddPatient,
}) => {
  const classes = useStyles();
  const [errorFields, setErrorFields] = useState<PatientInputFieldsEnum[]>([]);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const dateWrapperRef = useRef<HTMLDivElement>(null);
  const trials = useSelector(({ trial }: RootState) => trial.allTrials);

  useEffect(() => {
    if (isDatePickerOpen && dateWrapperRef.current) {
      // There is no way to get a ref to the calendar itself, so we rely
      //   on the fact that it is the first element after the calendar
      //   button to get a reference to it.
      const calendar = dateWrapperRef.current.querySelector('button + div');

      if (calendar) {
        calendar.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isDatePickerOpen]);

  // Change the default patient status if there is a trial change
  useEffect(() => {
    const trial = trials.find((trial) => trial.nctId === patientInputFields.NCT_ID);
    const patientStatus = shouldExcludePendingTempusReview(getEnrollingCohorts(trial))
      ? ''
      : PATIENT_TRACKER_STATUSES.PENDING_TEMPUS_REVIEW;

    updatePatientInputFields({ [PatientInputFieldsEnum.PATIENT_STATUS]: patientStatus });

    if (patientStatus) {
      removeFieldsFromErrorList([PatientInputFieldsEnum.PATIENT_STATUS]);
    }
  }, [patientInputFields.NCT_ID]);

  const trialSelectOptions: DropdownOption[] = trials.map((trial) => ({
    label: trial.shortName || trial.title,
    value: trial.nctId,
  }));

  const trial = trials.find((trial) => trial.nctId === patientInputFields.NCT_ID);
  const cohorts = getEnrollingCohorts(trial);

  const cohortSelectOptions: DropdownOption[] = formatCohortOptions(cohorts);

  const patientStatusOptions = useMemo(() => getPatientStatusOptions(shouldExcludePendingTempusReview(cohorts)), [
    patientInputFields.NCT_ID,
  ]);

  const removeFieldsFromErrorList = (fields: PatientInputFieldsEnum[]) => {
    const updatedErrorFields = [...errorFields];
    fields.forEach((field) => {
      const indexToRemove = updatedErrorFields.findIndex((f) => f === field);
      if (indexToRemove >= 0) {
        updatedErrorFields.splice(indexToRemove, 1);
      }
    });

    setErrorFields(updatedErrorFields);
  };

  const handleTrialChange = (option: DropdownOption | null) => {
    if (option) {
      removeFieldsFromErrorList([PatientInputFieldsEnum.NCT_ID]);
      updatePatientInputFields({ [PatientInputFieldsEnum.NCT_ID]: option.value, [PatientInputFieldsEnum.COHORT]: [] });
    }
  };

  const handleCohortChange = (options: CohortDropdownOption[]) => {
    if (options && options.length) {
      updatePatientInputFields({ [PatientInputFieldsEnum.COHORT]: options });
    }
  };

  const handleBiomarkerChange = (value: string) => {
    removeFieldsFromErrorList([PatientInputFieldsEnum.BIOMARKER]);
    updatePatientInputFields({ [PatientInputFieldsEnum.BIOMARKER]: value });
  };

  const handlePatientStatusChange = (option: DropdownOption | null) => {
    if (option) {
      updatePatientInputFields({ [PatientInputFieldsEnum.PATIENT_STATUS]: option.value });
      removeFieldsFromErrorList([PatientInputFieldsEnum.PATIENT_STATUS]);
    }
  };

  const handleCommentsChange = (value: string) => {
    removeFieldsFromErrorList([PatientInputFieldsEnum.COMMENTS]);
    updatePatientInputFields({ [PatientInputFieldsEnum.COMMENTS]: value });
  };

  const handleDateOnChange = (date: DateInputValue) => {
    updatePatientInputFields({ [PatientInputFieldsEnum.NEXT_VISIT_DATE]: date.dateString });
    setIsDatePickerOpen(false);
  };

  const fieldsAreEmpty = () => {
    const flaggedFields: PatientInputFieldsEnum[] = [];

    const flagEmptyFields = (field, fieldValue) => {
      if (!fieldValue) {
        flaggedFields.push(field);
      }
    };

    flagEmptyFields(PatientInputFieldsEnum.NCT_ID, patientInputFields.NCT_ID);
    flagEmptyFields(PatientInputFieldsEnum.PATIENT_STATUS, patientInputFields.PATIENT_STATUS);
    flagEmptyFields(PatientInputFieldsEnum.COMMENTS, patientInputFields.COMMENTS);

    setErrorFields(flaggedFields);
    return Boolean(flaggedFields.length);
  };

  const onBack = () => {
    navigateToPage(AddPatientPage.IDENTIFICATION_PAGE);
  };

  const onAdd = async () => {
    if (fieldsAreEmpty()) {
      return;
    }

    await performAddPatient();
  };

  const setTheme = (field: PatientInputFieldsEnum) => {
    return errorFields.includes(field) ? InputTheme.Error : InputTheme.Default;
  };

  return (
    <React.Fragment>
      <div className={`${classes.contentContainer} ${classes.matchInformationContainer}`}>
        <h4 className={typography.subSectionHeader}>Match information</h4>
        <div className={classes.matchInformationContent}>
          <SingleSelectDropdown
            className="trial-dropdown"
            placeholder="Enter..."
            label="Trial"
            data-testid="match-information-trial"
            options={trialSelectOptions}
            value={trialSelectOptions.find(({ value }) => value === patientInputFields.NCT_ID) || null}
            onChange={handleTrialChange}
            status={setTheme(PatientInputFieldsEnum.NCT_ID)}
          />
          <MultiSelectDropdown
            className="cohort-dropdown"
            placeholder="Enter..."
            label="Cohort"
            data-testid="match-information-cohort"
            options={cohortSelectOptions}
            value={patientInputFields.COHORT}
            onChange={handleCohortChange}
            selectedItemsTextOverride={patientInputFields.COHORT.map((o) => o.label).join(', ')}
          />
          <TextInput
            classes={classes}
            label="Biomarker"
            data-testid="match-information-biomarker"
            onChange={handleBiomarkerChange}
            value={patientInputFields.BIOMARKER}
            placeholder="Enter..."
            status={setTheme(PatientInputFieldsEnum.BIOMARKER)}
          />
          <SingleSelectDropdown
            className="patient-status-input"
            placeholder="Enter..."
            label="Patient Status"
            data-testid="match-information-patient-status"
            options={patientStatusOptions}
            value={patientStatusOptions.find(({ value }) => value === patientInputFields.PATIENT_STATUS) || null}
            onChange={handlePatientStatusChange}
            status={setTheme(PatientInputFieldsEnum.PATIENT_STATUS)}
          />
          <TextArea
            textAreaType="primary"
            className="comments-input"
            label="Note from site"
            data-testid="match-information-note-from-site"
            placeholder="Enter additional information here..."
            value={patientInputFields.COMMENTS}
            onChange={handleCommentsChange}
            status={setTheme(PatientInputFieldsEnum.COMMENTS)}
          />
          <div className={classes.patientVisitDetails}>
            <div id="patient-next-visit-wrapper" ref={dateWrapperRef}>
              <SingleDatePicker
                label="Next visit"
                data-testid="match-information-next-visit"
                isOpen={isDatePickerOpen}
                onToggleIsOpen={() => setIsDatePickerOpen(!isDatePickerOpen)}
                value={{ dateString: patientInputFields.NEXT_VISIT_DATE }}
                onChange={handleDateOnChange}
              />
            </div>
            <div id="patient-visit-type-wrapper">
              <SingleSelectDropdown
                label="Type"
                data-testid="match-information-next-visit-type"
                placeholder="Enter..."
                options={visitTypeOptions}
                value={visitTypeOptions.find(({ value }) => value === patientInputFields.VISIT_TYPE) || null}
                onChange={(option) =>
                  option ? updatePatientInputFields({ [PatientInputFieldsEnum.VISIT_TYPE]: option.value }) : undefined
                }
              />
            </div>
          </div>
        </div>
        <div className={classes.modalFooter}>
          <div className={classes.modalActions}>
            <Button
              className="secondary-button"
              buttonType="secondary"
              ariaLabel="Back"
              id="match-information-back-button"
              onClick={onBack}
              data-pendo-id="patient-tracker-match-information-back-button">
              Back
            </Button>
            <Button
              ariaLabel="Add"
              loading={isPersisting}
              onClick={onAdd}
              disabled={isPersisting}
              data-pendo-id="patient-tracker-match-information-add-button">
              Add
            </Button>
          </div>
          {errorFields.length > 0 && (
            <div className={classes.errorMessage}>
              Please enter missing information
              <Fail height={14} />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PatientMatchInformation;
