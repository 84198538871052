import {
  Activity,
  Annotation,
  BiomarkerFilter,
  DocumentPatchInput,
  DocumentView,
  PatientGeneticData,
  PatientNameSearch,
  ProgramSite,
  TimeProgramSiteStatus,
  Trial,
  ValueSet,
} from '@tempus/t-shared';
import { AxiosResponse } from 'axios';
import { SimpleContact } from 'institution-service-shared';

import { config } from '~/config';
import { UserSiteMap } from '~/store/site/types';
import { TrialSearchQuery, TrialSearchResult } from '~/store/trialSearch/state';

import { PatientTrackerCategoryCounts, PatientTrackerPatient, Patient, TimePatient } from '../patientTrackerV2/types';
import axios from './axios';
import {
  TrialDropdownOptionResponse,
  InstitutionsResponse,
  DocumentCreateResponse,
  DocumentVersionReviewData,
  SearchPatientRequestParams,
  GetTrialsBySiteRequest,
  GetTrialsBySiteResponse,
  GetPatientTrackerRecordsRequestBody,
  CreatePatientAndTrackingRecord,
  GetPatientTrackerCategoryCountsRequestBody,
  GetPatientsRequestBody,
  CreateNote,
  UpdateTimePatient,
  UpdateTimePatientV2,
} from './types';

export const TRIALS_API_ROOT = config.trialsApiRoot;

const UPLOAD_TIMEOUT = 60000 * 5;

const Api = {
  annotations: {
    get: (params): Promise<Annotation[]> =>
      axios
        .get(`${TRIALS_API_ROOT}/annotations`, { params })
        .then(({ data }) => data)
        .then(({ data }) => data),
  },
  trials: {
    getAll: (): Promise<TrialDropdownOptionResponse> =>
      axios.get(`${TRIALS_API_ROOT}/trials`, {
        params: {
          attributes: 'id,nctId,title,shortName,sponsor',
          bitOperationQuery: 'program_types=1,program_types=2',
          bitOperationOperator: 'OR',
          include: 'drug',
          'include.drug.attributes': 'name',
          overrideLimit: true,
        },
      }),
    getTrial: (trialId): Promise<Pick<Trial, 'id' | 'nctId' | 'title' | 'shortName'>> =>
      axios.get(`${TRIALS_API_ROOT}/v1/trials/${trialId}/external`).then(({ data }) => data),
  },
  documents: {
    getTrial: (trialId, params?: Record<string, unknown>): Promise<AxiosResponse<DocumentView[]>> =>
      axios.get(`${TRIALS_API_ROOT}/v1/documents/trial/${trialId}`, { params }),
    getSite: (siteId): Promise<AxiosResponse<DocumentView[]>> =>
      axios.get(`${TRIALS_API_ROOT}/v1/documents/site/${siteId}`),
    latestApplicableAtByTrial: (): Promise<Record<string, string>> =>
      axios
        .get<Record<string, string>>(`${TRIALS_API_ROOT}/v1/documents/trial/latest-applicable-at`)
        .then(({ data }) => data)
        .catch(() => ({})),
    create: (body): Promise<DocumentCreateResponse> =>
      axios.post(`${TRIALS_API_ROOT}/v1/documents/`, body, { timeout: UPLOAD_TIMEOUT }),
    deleteVersion: (documentVersionId: string): Promise<boolean> =>
      axios
        .delete(`${TRIALS_API_ROOT}/v1/documents/${documentVersionId}`)
        .then(() => true)
        .catch(() => false),
    patchVersion: (documentVersionId: string, updates: DocumentPatchInput): Promise<DocumentView | false> =>
      axios
        .patch<DocumentView>(`${TRIALS_API_ROOT}/v1/documents/${documentVersionId}`, updates)
        .then(({ data }) => data)
        .catch(() => false),
    getVersionReview: (documentVersionId: string): Promise<DocumentVersionReviewData | false> =>
      axios
        .get(`${TRIALS_API_ROOT}/v1/documents/${documentVersionId}/review`)
        .then(({ data }) => data)
        .catch(() => {
          return false;
        }),
    getTags: (): Promise<string[]> =>
      axios
        .get<string[]>(`${TRIALS_API_ROOT}/v1/documentTags`)
        .then(({ data }) => data)
        .catch(() => []),
    getValuesForTag: (key: string): Promise<string[]> =>
      axios
        .get<string[]>(`${TRIALS_API_ROOT}/v1/documentTags/${key}`)
        .then(({ data }) => data)
        .catch(() => []),
  },
  timeSites: {
    getAll: (): Promise<InstitutionsResponse> =>
      axios.get(`${TRIALS_API_ROOT}/institutions`, {
        params: {
          attributes: 'id,name,shortName',
          overrideLimit: true,
          'programBehaviors->TIME': 'site',
        },
      }),
    getUserSites: (): Promise<UserSiteMap> =>
      axios.get(`${TRIALS_API_ROOT}/v1/institutions/sites/user`).then(({ data }) => data),
  },

  patientTrackerV2: {
    getPatientTrackerCategoryCounts: (
      body: GetPatientTrackerCategoryCountsRequestBody,
    ): Promise<PatientTrackerCategoryCounts> => {
      return axios
        .post<PatientTrackerCategoryCounts>(`${TRIALS_API_ROOT}/v2/patient-tracker/status-count`, body)
        .then(({ data }) => data);
    },
    getPatientRecords: (body: GetPatientTrackerRecordsRequestBody): Promise<TimePatient[]> => {
      return axios.post<TimePatient[]>(`${TRIALS_API_ROOT}/v2/patient-tracker`, body).then(({ data }) => data);
    },
    getPatientRecord: (institutionId: string, timePatientId: string): Promise<PatientTrackerPatient> => {
      return axios
        .get<PatientTrackerPatient>(
          `${TRIALS_API_ROOT}/v2/patient-tracker/institution/${institutionId}/?timePatientId=${timePatientId}`,
        )
        .then(({ data }) => data);
    },
    getPatientRecordsCsv: (body: Partial<GetPatientTrackerRecordsRequestBody>): Promise<Blob> => {
      return axios.post(`${TRIALS_API_ROOT}/v2/patient-tracker/csv`, body).then(({ data }) => data);
    },
    createPatientAndTrackingRecord: (institutionId: string, body: CreatePatientAndTrackingRecord) => {
      return axios
        .post(`${TRIALS_API_ROOT}/v2/patient-tracker/institution/${institutionId}/patient`, body)
        .then(({ data }) => data);
    },
    getPatients: (body: GetPatientsRequestBody): Promise<Patient[]> => {
      return axios
        .post<Patient[]>(`${TRIALS_API_ROOT}/v2/patient-tracker/patient-search`, body)
        .then(({ data }) => data);
    },
  },
  contact: {
    getMultipleContacts: (ids: string[]): Promise<AxiosResponse<SimpleContact[]>> => {
      return axios.post(`${TRIALS_API_ROOT}/institutions/contacts`, { ids });
    },
  },

  trialMatches: {
    getTrialMatches: (
      searchQuery: TrialSearchQuery,
      siteId: string | null,
      statuses: TimeProgramSiteStatus[],
    ): Promise<AxiosResponse<TrialSearchResult[]>> => {
      return axios.post(`${TRIALS_API_ROOT}/v1/trial-matches/sites`, { searchQuery, siteId, statuses });
    },
  },

  dropdowns: {
    getBiomarkers: (search): Promise<BiomarkerFilter[]> => {
      return axios
        .get(`${TRIALS_API_ROOT}/v1/dropdowns/biomarker-filters`, { params: { search } })
        .then(({ data }) => data);
    },
    getValueSet: (valuesetName: string): Promise<ValueSet[]> => {
      return axios.get(`${TRIALS_API_ROOT}/valuesets/${valuesetName}`).then(({ data }) => data);
    },
  },

  patients: {
    searchPatient: (params: SearchPatientRequestParams): Promise<PatientNameSearch[]> => {
      return axios.get(`${TRIALS_API_ROOT}/v1/patients/search`, { params }).then(({ data }) => data);
    },
    fetchPatientGeneticData: (patientId: string): Promise<PatientGeneticData> => {
      return axios.get(`${TRIALS_API_ROOT}/v1/patients/${patientId}`).then(({ data }) => data);
    },
    createNote: (patientLinkageId: string, body: CreateNote): Promise<Activity> => {
      return axios.post(`${TRIALS_API_ROOT}/v1/patients/${patientLinkageId}/note`, body).then(({ data }) => data);
    },
    updateTimePatient: (patientLinkageId: string, institutionId: string, body: UpdateTimePatient) => {
      return axios.post(`${TRIALS_API_ROOT}/v1/patients/timePatient/${patientLinkageId}/${institutionId}`, body);
    },
  },
  programSites: {
    getProgramSite: (institutionId: string, trialId: string): Promise<Pick<ProgramSite, 'status'>> =>
      axios
        .get(`${TRIALS_API_ROOT}/programSites/institution/${institutionId}/trial/${trialId}/external`)
        .then(({ data }) => data),
  },

  trialSitesSearch: {
    getTrialsBySite: (body: GetTrialsBySiteRequest): Promise<GetTrialsBySiteResponse> => {
      return axios.post(`${TRIALS_API_ROOT}/v1/trial-sites-search`, body).then(({ data }) => data);
    },
  },
  email: {
    sendExternalInterestEmail: (siteId: string, trialId: string): Promise<void> => {
      return axios.post(`${TRIALS_API_ROOT}/v1/email/site/${siteId}/trial/${trialId}`);
    },
  },

  users: {
    getCurrentUser: () => axios.get(`${TRIALS_API_ROOT}/v1/users/current-user`).then(({ data }) => data),
  },
  timePatient: {
    updateTimePatientV2: (timePatientId: string, updates: UpdateTimePatientV2) => {
      return axios.patch(`${TRIALS_API_ROOT}/v2/time-patients/${timePatientId}`, updates).then(({ data }) => data);
    },
  },
};

export default Api;
