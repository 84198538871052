import { GrayscalePalette, WarningPalette } from '@tcl-boron-colors/colors';
import { Tooltip } from '@tcl-boron-prefabs/tooltip';
import { TimePatientStatuses, TimePatientStatusesDetail } from '@tempus/t-shared';
import React, { useState } from 'react';

import { tooltipComponentClasses, useStyles } from './styles';

const createStatusDetailMap = (
  statusEnum: typeof TimePatientStatuses,
  detailEnum: typeof TimePatientStatusesDetail,
): { [key: string]: string } => {
  const map: { [key: string]: string } = {};
  const statusKeys = Object.keys(statusEnum) as Array<keyof typeof statusEnum>;

  for (const statusKey of statusKeys) {
    if (detailEnum[statusKey]) {
      map[statusEnum[statusKey]] = detailEnum[statusKey];
    }
  }

  return map;
};

const timeSensitiveStatuses = [
  TimePatientStatuses.PENDING_REVIEW,
  TimePatientStatuses.REVIEWING_NEXT_LINE_OPTIONS,
  TimePatientStatuses.SHOWING_SIGNS_OF_PROGRESSION_PENDING_FURTHER_WORKUP,
];

interface PatientStatusProps {
  status: TimePatientStatuses;
}

export const PatientStatus: React.FC<PatientStatusProps> = ({ status }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const statusDetailMap = createStatusDetailMap(TimePatientStatuses, TimePatientStatusesDetail);

  const handleMouseEnter = () => {
    if (!statusDetailMap[status]) {
      return;
    }
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    if (!statusDetailMap[status]) {
      return;
    }
    setIsOpen(false);
  };
  if (!status) {
    return null;
  }

  return (
    <>
      <div>
        <Tooltip isOpen={isOpen} position="below-center" classes={tooltipComponentClasses()}>
          {{
            tooltip: <div className={classes.statusTooltip}>{statusDetailMap[status]}</div>,
            base: (
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={classes.status}
                style={{
                  background: timeSensitiveStatuses.includes(status as TimePatientStatuses)
                    ? WarningPalette[100]
                    : GrayscalePalette[300],
                }}>
                {status}
              </div>
            ),
          }}
        </Tooltip>
      </div>
    </>
  );
};

export default PatientStatus;
