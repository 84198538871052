import { TrialType } from '@tempus/t-shared';
import { DEFAULT_TABLE_PAGE_SIZE } from '@tempus/t-shared/ui';
import { Reducer } from 'redux';

import { PATIENT_TRACKER_STATUS_CATEGORIES } from './constants';
import {
  PatientTrackerActionTypes,
  PatientTrackerStateMap,
  SET_PATIENT_TRACKER_V2_RECORDS,
  GET_PATIENT_TRACKER_V2_RECORDS_FAILED,
  GET_PATIENT_TRACKER_V2_RECORDS,
  GET_PATIENT_TRACKER_V2_RECORD,
  GET_PATIENT_TRACKER_V2_RECORD_FAILED,
  SET_PATIENT_TRACKER_V2_RECORD,
  GET_PATIENT_TRACKER_V2_RECORDS_CSV,
  GET_PATIENT_TRACKER_V2_RECORDS_CSV_FAILED,
  SET_PATIENT_TRACKER_V2_RECORDS_CSV,
  SET_PATIENT_TRACKER_V2_STATUS_CATEGORY,
  SET_PATIENT_TRACKER_V2_CATEGORY_COUNTS,
  GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS,
  SET_PATIENT_TRACKER_V2_UPDATE_MODAL,
  SET_PATIENT_TRACKER_V2_EDIT_OVERLAY,
  SET_PATIENT_TRACKER_V2_FILTERS,
  SET_PATIENT_TRACKER_V2_PAGINATION,
  PatientTrackerStateId,
  CLEAR_PATIENT_TRACKER_V2_RECORD,
} from './types';

export const defaultFilters = {
  sortOrder: { label: 'Urgency', value: 'Urgency' },
  trialName: null,
  trialStatus: [],
  physician: '',
  indications: [],
  biomarker: '',
  patientName: '',
  note: '',
  trialType: { label: TrialType.INTERVENTIONAL, value: TrialType.INTERVENTIONAL },
  patientId: '',
  rnAssignment: '',
};

const defaultPatientTrackerState = {
  fetchingRecords: false,
  siteId: '',
  statusCategory: PATIENT_TRACKER_STATUS_CATEGORIES.NEW,
  patients: [],
  patientsMetadata: {
    totalRecords: 0,
    numRecords: 15,
    skip: 0,
    top: DEFAULT_TABLE_PAGE_SIZE,
  },
  categoryCounts: {
    [PATIENT_TRACKER_STATUS_CATEGORIES.NEW]: null,
    [PATIENT_TRACKER_STATUS_CATEGORIES.PRIORITY]: null,
    [PATIENT_TRACKER_STATUS_CATEGORIES.MONITORING]: null,
    [PATIENT_TRACKER_STATUS_CATEGORIES.ENROLLED]: null,
    [PATIENT_TRACKER_STATUS_CATEGORIES.INACTIVE]: null,
  },
  updateModal: {
    selectedPatientRecord: null,
    isOpen: false,
  },
  editOverlay: {
    selectedPatientRecord: null,
    isOpen: false,
  },
  filters: defaultFilters,
  exports: {
    loading: false,
    error: null,
  },
  patient: {
    loading: false,
    error: null,
  },
};

const defaultState: PatientTrackerStateMap = {
  [PatientTrackerStateId.PATIENT_TRACKER_V2]: defaultPatientTrackerState,
  [PatientTrackerStateId.TRIAL_DOCUMENTS_PAGE_PATIENTS]: defaultPatientTrackerState,
};

const reducer: Reducer<PatientTrackerStateMap, PatientTrackerActionTypes> = (
  state: PatientTrackerStateMap = defaultState,
  action: PatientTrackerActionTypes,
): PatientTrackerStateMap => {
  switch (action.type) {
    case SET_PATIENT_TRACKER_V2_STATUS_CATEGORY: {
      const { id, statusCategory } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          statusCategory,
        },
      };
    }

    case GET_PATIENT_TRACKER_V2_RECORDS: {
      const { id } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          fetchingRecords: true,
          patients: [],
        },
      };
    }

    case GET_PATIENT_TRACKER_V2_RECORDS_FAILED: {
      const { id } = action;

      return {
        ...state,
        [id]: {
          ...state[id],
          fetchingRecords: false,
        },
      };
    }

    case SET_PATIENT_TRACKER_V2_RECORDS: {
      const { id, patients } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          fetchingRecords: false,
          patients,
        },
      };
    }

    case GET_PATIENT_TRACKER_V2_RECORD: {
      const { id } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          patient: {
            ...state[id].patient,
            loading: true,
          },
        },
      };
    }

    case GET_PATIENT_TRACKER_V2_RECORD_FAILED: {
      const { id, error } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          patient: {
            ...state[id].patient,
            loading: false,
            error,
          },
        },
      };
    }

    case CLEAR_PATIENT_TRACKER_V2_RECORD: {
      const { id } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          patient: {
            loading: false,
            error: null,
            data: undefined,
          },
        },
      };
    }

    case SET_PATIENT_TRACKER_V2_RECORD: {
      const { id, patient } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          fetchingRecords: false,
          patient: {
            ...state[id].patient,
            loading: false,
            data: patient,
          },
        },
      };
    }

    case GET_PATIENT_TRACKER_V2_RECORDS_CSV: {
      const { id } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          exports: {
            ...state[id].exports,
            loading: true,
          },
        },
      };
    }

    case GET_PATIENT_TRACKER_V2_RECORDS_CSV_FAILED: {
      const { id, error } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          exports: {
            ...state[id].exports,
            loading: false,
            error,
          },
        },
      };
    }

    case SET_PATIENT_TRACKER_V2_RECORDS_CSV: {
      const { id, data, fileName } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          exports: {
            ...state[id].exports,
            fileName,
            data,
            loading: false,
          },
        },
      };
    }

    case GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS: {
      const { id, siteId } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          categoryCounts: {
            [PATIENT_TRACKER_STATUS_CATEGORIES.NEW]: null,
            [PATIENT_TRACKER_STATUS_CATEGORIES.PRIORITY]: null,
            [PATIENT_TRACKER_STATUS_CATEGORIES.MONITORING]: null,
            [PATIENT_TRACKER_STATUS_CATEGORIES.ENROLLED]: null,
            [PATIENT_TRACKER_STATUS_CATEGORIES.INACTIVE]: null,
          },
          siteId: siteId,
          patients: [],
        },
      };
    }

    case SET_PATIENT_TRACKER_V2_CATEGORY_COUNTS: {
      const { id, categoryCounts } = action;
      return { ...state, [id]: { ...state[id], categoryCounts } };
    }

    case SET_PATIENT_TRACKER_V2_UPDATE_MODAL: {
      const { id, selectedPatientRecord, isOpen } = action;
      const selectedRecord = selectedPatientRecord || state[id].updateModal.selectedPatientRecord;
      const modalOpen = isOpen !== undefined ? isOpen : state[id].updateModal.isOpen;

      return {
        ...state,
        [id]: { ...state[id], updateModal: { selectedPatientRecord: selectedRecord, isOpen: modalOpen } },
      };
    }

    case SET_PATIENT_TRACKER_V2_EDIT_OVERLAY: {
      const { id, selectedPatientRecord, isOpen } = action;
      const selectedRecord = selectedPatientRecord || state[id].editOverlay.selectedPatientRecord;
      const overlayOpen = isOpen !== undefined ? isOpen : state[id].editOverlay.isOpen;

      return {
        ...state,
        [id]: { ...state[id], editOverlay: { selectedPatientRecord: selectedRecord, isOpen: overlayOpen } },
      };
    }

    case SET_PATIENT_TRACKER_V2_FILTERS: {
      const { id, filters } = action;
      const updatedFilters = { ...state[id].filters, ...filters };
      return { ...state, [id]: { ...state[id], filters: updatedFilters } };
    }

    case SET_PATIENT_TRACKER_V2_PAGINATION: {
      const { id, numRecords, skip, totalRecords } = action;
      const updatedPatientMetaData = { numRecords, skip, totalRecords };
      return { ...state, [id]: { ...state[id], patientsMetadata: updatedPatientMetaData } };
    }

    default:
      return state;
  }
};

export default reducer;
