import { ActionPalette } from '@tcl-boron-colors/colors';
import { Fail } from '@tcl-boron-icons/icons';
import { Button } from '@tcl-boron-prefabs/button';
import { SingleDatePicker } from '@tcl-boron-prefabs/single-date-picker';
import { SingleSelectTypeahead } from '@tcl-boron-prefabs/single-select-typeahead';
import { TextInput } from '@tcl-boron-prefabs/text-input';
import React, { useState } from 'react';
import { DropdownOption, InputTheme } from 'tcl-v3/models';
import typography from 'tcl-v3/styles/typography.module.scss';

import api from '~/store/api';
import { Patient, TimePatient } from '~/store/patientTrackerV2/types';
import { formatDateToMonthDayYear } from '~/utils/misc';

import useStyles from '../styles';
import { PatientInputFieldsEnum, PatientInputFields, PatientInputFieldsUpdates, AddPatientPage } from '../types';

export const createNewPatientOptionDiv = (inputValue) => {
  if (inputValue) {
    return <div style={{ color: ActionPalette[300] }}>Create &quot;{inputValue}&quot;</div>;
  } else {
    return null;
  }
};

interface PatientIdentificationProps {
  siteId: string;
  patientInputFields: PatientInputFields;
  updatePatientInputFields: (updates: PatientInputFieldsUpdates) => void;
  closeModal: () => void;
  navigateToPage: (page: AddPatientPage) => void;
  setDuplicateMatchRecord: (record: TimePatient) => void;
  setSelectedPatientLinkageId: (linkageId: string | undefined) => void;
}

const PatientIdentification: React.FC<PatientIdentificationProps> = ({
  siteId,
  patientInputFields,
  updatePatientInputFields,
  closeModal,
  navigateToPage,
  setDuplicateMatchRecord,
  setSelectedPatientLinkageId,
}) => {
  const CREATE_NEW_PATIENT = 'CREATE_NEW_PATIENT';
  const classes = useStyles();

  const [errorFields, setErrorFields] = useState<PatientInputFieldsEnum[]>([]);
  const [checkingIsAlreadyScreened, setCheckingIsAlreadyScreened] = useState(false);
  const [isLoadingPatients, setIsLoadingPatients] = useState(false);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [formattedPatientOptions, setFormattedPatientOptions] = useState<DropdownOption[]>([]);

  const [firstNameInputValue, setFirstNameInputValue] = useState('');
  const [lastNameInputValue, setLastNameInputValue] = useState('');

  const formatPatientDropdownOptions = (patients: Patient[]): DropdownOption[] => {
    return patients.map((p) => {
      const dob = `DOB ${formatDateToMonthDayYear(p.dateOfBirth || '')}`;
      return {
        label: `${p.firstName} ${p.lastName}`,
        value: p.linkageId,
        subContent: dob,
      };
    });
  };

  const fieldsAreEmpty = () => {
    const flaggedFields: PatientInputFieldsEnum[] = [];

    const flagEmptyFields = (field, fieldValue) => {
      if (!fieldValue) {
        flaggedFields.push(field);
      }
    };

    flagEmptyFields(PatientInputFieldsEnum.FIRST_NAME, patientInputFields.FIRST_NAME);
    flagEmptyFields(PatientInputFieldsEnum.LAST_NAME, patientInputFields.LAST_NAME);
    flagEmptyFields(PatientInputFieldsEnum.DOB, patientInputFields.DOB);
    flagEmptyFields(PatientInputFieldsEnum.MRN, patientInputFields.MRN);

    setErrorFields(flaggedFields);
    return Boolean(flaggedFields.length);
  };

  const onCancel = () => {
    closeModal();
  };

  const onNext = async () => {
    if (fieldsAreEmpty()) {
      return;
    }

    if (patientInputFields.TRIAL_ID && patientInputFields.FIRST_NAME && patientInputFields.LAST_NAME) {
      setCheckingIsAlreadyScreened(true);

      const patients = await api.patientTrackerV2.getPatientRecords({
        institutionId: siteId,
        patientFullName: `${patientInputFields.FIRST_NAME} ${patientInputFields.LAST_NAME}`,
        trialId: patientInputFields.TRIAL_ID,
      });
      const existingRecord = patients.length ? patients[0] : null;

      const passedRnReview = existingRecord?.patientTrackingDetails?.[0]?.passedRnReview;
      if (existingRecord && (passedRnReview === 'Yes' || !passedRnReview)) {
        setDuplicateMatchRecord(patients[0]);
        navigateToPage(AddPatientPage.DUPLICATE_MATCH_PAGE);
      } else if (existingRecord) {
        navigateToPage(AddPatientPage.INELIGIBLE_MATCH_PAGE);
      } else {
        navigateToPage(AddPatientPage.MATCH_INFORMATION_PAGE);
      }

      setCheckingIsAlreadyScreened(false);
    } else {
      navigateToPage(AddPatientPage.MATCH_INFORMATION_PAGE);
    }
  };

  const removeFieldsFromErrorList = (fields: PatientInputFieldsEnum[]) => {
    const updatedErrorFields = [...errorFields];
    fields.forEach((field) => {
      const indexToRemove = updatedErrorFields.findIndex((f) => f === field);
      if (indexToRemove >= 0) {
        updatedErrorFields.splice(indexToRemove, 1);
      }
    });

    setErrorFields(updatedErrorFields);
  };

  const handleFirstNameChange = (option: DropdownOption | null | undefined) => {
    if (option) {
      if (option.value === CREATE_NEW_PATIENT) {
        updatePatientInputFields({ [PatientInputFieldsEnum.FIRST_NAME]: firstNameInputValue });
        setSelectedPatientLinkageId(undefined);
        removeFieldsFromErrorList([PatientInputFieldsEnum.FIRST_NAME]);
      } else {
        const patient = patients.find((p) => p.linkageId === option?.value);
        updatePatientInputFields({
          [PatientInputFieldsEnum.FIRST_NAME]: patient?.firstName,
          [PatientInputFieldsEnum.LAST_NAME]: patient?.lastName,
          [PatientInputFieldsEnum.DOB]: formatDateToMonthDayYear(patient?.dateOfBirth || ''),
          [PatientInputFieldsEnum.MRN]:
            patient?.patientTrackingDetails.find((ptd) => ptd.institutionId == siteId)?.mrn || '',
        });
        removeFieldsFromErrorList([
          PatientInputFieldsEnum.FIRST_NAME,
          PatientInputFieldsEnum.LAST_NAME,
          PatientInputFieldsEnum.DOB,
          PatientInputFieldsEnum.MRN,
        ]);
        setSelectedPatientLinkageId(patient?.linkageId);
      }
    }
  };

  const handleLastNameChange = (option: DropdownOption | null | undefined) => {
    if (option) {
      if (option.value === CREATE_NEW_PATIENT) {
        updatePatientInputFields({ [PatientInputFieldsEnum.LAST_NAME]: lastNameInputValue });
        setSelectedPatientLinkageId(undefined);
        removeFieldsFromErrorList([PatientInputFieldsEnum.LAST_NAME]);
      } else {
        const patient = patients.find((p) => p.linkageId === option?.value);
        updatePatientInputFields({
          [PatientInputFieldsEnum.FIRST_NAME]: patient?.firstName,
          [PatientInputFieldsEnum.LAST_NAME]: patient?.lastName,
          [PatientInputFieldsEnum.DOB]: formatDateToMonthDayYear(patient?.dateOfBirth || ''),
          [PatientInputFieldsEnum.MRN]:
            patient?.patientTrackingDetails.find((ptd) => ptd.institutionId == siteId)?.mrn || '',
        });
        removeFieldsFromErrorList([
          PatientInputFieldsEnum.FIRST_NAME,
          PatientInputFieldsEnum.LAST_NAME,
          PatientInputFieldsEnum.DOB,
          PatientInputFieldsEnum.MRN,
        ]);

        setSelectedPatientLinkageId(patient?.linkageId);
      }
    }
  };

  const handleDobChange = ({ dateString }) => {
    removeFieldsFromErrorList([PatientInputFieldsEnum.DOB]);
    updatePatientInputFields({ [PatientInputFieldsEnum.DOB]: dateString });
  };

  const setTheme = (field: PatientInputFieldsEnum) => {
    return errorFields.includes(field) ? InputTheme.Error : InputTheme.Default;
  };

  const handleFirstNameInput = (e) => {
    setFirstNameInputValue(e);

    setIsLoadingPatients(e ? true : false);
  };

  const handleLastNameInput = (e) => {
    setLastNameInputValue(e);

    setIsLoadingPatients(e ? true : false);
  };

  const handleMrnChange = (mrn: string) => {
    removeFieldsFromErrorList([PatientInputFieldsEnum.MRN]);
    updatePatientInputFields({ [PatientInputFieldsEnum.MRN]: mrn });
  };

  const searchPatients = async (e) => {
    const patients = e
      ? await api.patientTrackerV2.getPatients({
          firstName: firstNameInputValue,
          lastName: lastNameInputValue,
          institutionId: siteId,
        })
      : await api.patientTrackerV2.getPatients({
          institutionId: siteId,
        });
    setPatients(patients);
    const formattedOptions = formatPatientDropdownOptions(patients);

    const createNewPatientOption = { label: '', value: CREATE_NEW_PATIENT, subContent: createNewPatientOptionDiv(e) };
    formattedOptions.push(createNewPatientOption);
    setFormattedPatientOptions(formattedOptions);

    setIsLoadingPatients(false);
  };

  return (
    <React.Fragment>
      <div className={`${classes.contentContainer} ${classes.identificationContainer}`}>
        <h4 className={typography.subSectionHeader}>Identification</h4>
        <div className={`${classes.identificationContent}`}>
          <SingleSelectTypeahead
            clearable={true}
            onChange={handleFirstNameChange}
            options={formattedPatientOptions}
            value={{ label: patientInputFields.FIRST_NAME, value: patientInputFields.FIRST_NAME } as DropdownOption}
            isLoading={isLoadingPatients}
            inputValue={firstNameInputValue}
            onInputValueChange={(e) => handleFirstNameInput(e)}
            onDebounceChange={(e) => searchPatients(e)}
            label={'First name'}
            data-testid={'add-patient-first-name'}
            status={setTheme(PatientInputFieldsEnum.FIRST_NAME)}
          />
          <SingleSelectTypeahead
            clearable={true}
            onChange={handleLastNameChange}
            options={formattedPatientOptions}
            value={{ label: patientInputFields.LAST_NAME, value: patientInputFields.LAST_NAME } as DropdownOption}
            isLoading={isLoadingPatients}
            inputValue={lastNameInputValue}
            onInputValueChange={(e) => handleLastNameInput(e)}
            onDebounceChange={(e) => searchPatients(e)}
            label={'Last name'}
            data-testid={'add-patient-last-name'}
            status={setTheme(PatientInputFieldsEnum.LAST_NAME)}
          />
          <SingleDatePicker
            classes={classes}
            label="Date of Birth"
            data-testid="add-patient-date-of-birth"
            value={{ dateString: patientInputFields.DOB || '' }}
            onChange={handleDobChange}
            status={setTheme(PatientInputFieldsEnum.DOB)}
            minYear={1900}
          />
          <TextInput
            classes={classes}
            label="Mrn"
            data-testid="add-patient-Mrn"
            onChange={handleMrnChange}
            value={patientInputFields.MRN}
            placeholder="Enter..."
            status={setTheme(PatientInputFieldsEnum.MRN)}
          />
        </div>
        <div className={classes.modalFooter}>
          <div className={classes.modalActions}>
            <Button
              small
              id="identification-cancel-button"
              className="secondary-button"
              buttonType="secondary"
              ariaLabel="Cancel"
              onClick={onCancel}
              data-pendo-id="patient-tracker-add-patient-cancel-button">
              Cancel
            </Button>
            <Button
              small
              ariaLabel="Next"
              onClick={onNext}
              disabled={checkingIsAlreadyScreened}
              data-pendo-id="patient-tracker-add-patient-next">
              Next
            </Button>
          </div>
          {errorFields.length > 0 && (
            <div className={classes.errorMessage}>
              Please enter missing information
              <Fail height={14} />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PatientIdentification;
