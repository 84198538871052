import { createUseStyles } from 'react-jss';

export default createUseStyles({
  overlay: {
    '& button[name="close_overlay"]': {
      zIndex: 9999,
    },
  },
  overlayLoader: {
    padding: '24px',
    display: 'flex',
    itemAlign: 'center',
    gap: '5px',
    fontWeight: 800,
  },
  overlayError: {
    height: '50px',
    margin: '24px',
  },
});

export const overlayStyles = {
  content: {
    width: '1200px',
    zIndex: 9999,
    // overlay should not scroll as a whole
    overflow: 'hidden',
    display: 'flex',
    padding: '0px',
  },
  overlay: {
    position: 'fixed',
    inset: 0,
    zIndex: 6,
  },
};

// The default close button does not allow the functionality we need, hide it
export const overlayOverrideClasses = createUseStyles({
  closeButton: {
    display: 'none',
    pointerEvents: 'none',
  },
});
